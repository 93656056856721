import { Container, Row, Col } from "react-bootstrap";
import facebookIcon from "../assets/img/facebook-icon.svg";
import telegramIcon from "../assets/img/telegram-icon.svg";
import gmailIcon from "../assets/img/gmail-icon.svg";

export const Footer = () => {
  return (
    <footer
      className="footer"
      id="follow"
      style={{
        backgroundColor: "#0B1131",
        padding: "15px 0",
        color: "#fff",
        fontSize: "14px",
      }}
    >
      <Container>
        <Row className="align-items-center text-center text-md-left">
          {/* Contact Section */}
          <Col xs={12} md={6}>
            <h5 className="footer-heading">Contact Us</h5>
            <div className="social-icons">
              {/* Facebook */}
              <div className="social-item">
                <img
                  src={facebookIcon}
                  alt="Facebook"
                  className="social-icon"
                />
                <span>ethioexammastery</span>
              </div>
              {/* Telegram */}
              <div className="social-item">
                <img
                  src={telegramIcon}
                  alt="Telegram"
                  className="social-icon"
                />
                <span>@ethioexamsmastery</span>
              </div>
              {/* Gmail */}
              <div className="social-item">
                <img src={gmailIcon} alt="Gmail" className="social-icon" />
                <span>ethioexammastery@gmail.com</span>
              </div>
            </div>
          </Col>

          {/* Copyright Section */}
          <Col xs={12} md={6} className="text-center text-md-right">
            <p style={{ margin: "10px 0" }}>
              Done by: <strong>Ethio Exam Mastery</strong>
            </p>
            <p>Copyright &copy; 2024. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};