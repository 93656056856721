import { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import output1 from "../assets/img/output1.png";
import "animate.css";
import { db } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import TrackVisibility from "react-on-screen";
import "../styles/contact.css"; // Assuming a CSS file for styles

export const Contact = () => {
  const [selectedTab, setSelectedTab] = useState("Student");
  const [formDetails, setFormDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    gender: "",
    address: "",
    applyFor: "Student",
    skillOrSubject: "",
    currentLevel: "",
    mode: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState(null);

  const handleFormUpdate = (field, value) => {
    setFormDetails((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const { firstName, lastName, email, phone, address, applyFor, skillOrSubject } = formDetails;

    // Basic Validation
    if (!firstName || !lastName || !email || !phone || !address || !applyFor || !skillOrSubject) {
      setStatusMessage({ type: "error", text: "Please fill all required fields." });
      setIsSubmitting(false);
      hideMessageAfterDelay();
      return;
    }

    // Email & Phone Validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]+$/;

    if (!emailRegex.test(email)) {
      setStatusMessage({ type: "error", text: "Invalid email format." });
      setIsSubmitting(false);
      hideMessageAfterDelay();
      return;
    }

    if (!phoneRegex.test(phone)) {
      setStatusMessage({ type: "error", text: "Invalid phone format." });
      setIsSubmitting(false);
      hideMessageAfterDelay();
      return;
    }

    try {
      const collectionName = applyFor === "Student" ? "students" : "teachers";
      await addDoc(collection(db, collectionName), {
        ...formDetails,
        timestamp: new Date(),
      });

      setFormDetails({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        gender: "",
        address: "",
        applyFor: selectedTab,
        skillOrSubject: "",
        currentLevel: "",
        mode: "",
        message: "",
      });

      setStatusMessage({ type: "success", text: "Form submitted successfully! (Ethio Exam Mastery ስለመረጡ እናመሰግናለን። እንደውልሎታለን)" });
    } catch (error) {
      console.error("Error submitting form: ", error);
      setStatusMessage({ type: "error", text: "Failed to submit the form. (በኋላ ይሞክሩ)" });
    } finally {
      setIsSubmitting(false);
      hideMessageAfterDelay();
    }
  };

  const hideMessageAfterDelay = () => {
    setTimeout(() => setStatusMessage(null), 5000);
  };

  return (
    <section className="contact" id="apply">
      <Container>
      <h2 
  className="text-center" 
  style={{
    fontSize: "2.5rem",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#fff",
    textShadow: "0 2px 4px rgba(0, 0, 0, 0.4)",
  }}
>
  Apply as{" "}
  <span style={{ color: "#52A7C5"  }}>Student</span> or{" "}
  <span style={{ color: "#E96293" }}>Teacher</span>
</h2>
<h4
  className="text-center"
  style={{
    fontSize: "1.5rem",
    fontWeight: "500",
    marginTop: "10px",
    marginBottom: "30px",
    color: "#fff",
    textShadow: "0 1px 3px rgba(0, 0, 0, 0.3)",
    animation: "fadeIn 3s ease-in-out",
  }}
>
  <span style={{ color: "#52A7C5" }}>WELCOME TO</span>{" "}
  <span style={{ color: "#E96293" }}>ETHIO EXAM MASTERY TUTOR</span>
</h4>
        <Row className="align-items-center">
          <Col md={4}>
            <TrackVisibility>
              {({ isVisible }) => (
                <img
                  className={isVisible ? "animate__animated animate__zoomIn" : ""}
                  src={output1}
                  alt="Apply"
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    height: "auto",
                    display: "block",
                    margin: "0 auto",
                  }}
                />
              )}
            </TrackVisibility>
          </Col>
          <Col md={8}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
               
                  <div
  className="tabs"
  style={{
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    margin: "20px 0",
  }}
>
  <Button
    variant="none"
    onClick={() => {
      setSelectedTab("Student");
      handleFormUpdate("applyFor", "Student");
    }}
    style={{
      padding: "10px 20px",
      fontWeight: "bold",
      color: selectedTab === "Student" ? "#fff" : "#52A7C5",
      backgroundColor: selectedTab === "Student" ? "#007bff" : "transparent",
      border: "2px solid #52A7C5",
      borderRadius: "5px",
      transition: "all 0.3s ease-in-out",
      boxShadow:
        selectedTab === "Student"
          ? "0 4px 10px rgba(0, 123, 255, 0.5)"
          : "none",
      cursor: "pointer",
    }}
    onMouseEnter={(e) =>
      (e.target.style.backgroundColor = selectedTab === "Student"
        ? "#52A7C5"
        : "#f0f0f0")
    }
    onMouseLeave={(e) =>
      (e.target.style.backgroundColor = selectedTab === "Student"
        ? "#52A7C5"
        : "transparent")
    }
  >
    Student
  </Button>
  <Button
    variant="none"
    onClick={() => {
      setSelectedTab("Teacher");
      handleFormUpdate("applyFor", "Teacher");
    }}
    style={{
      padding: "10px 20px",
      fontWeight: "bold",
      color: selectedTab === "Teacher" ? "#fff" : "#E96193",
      backgroundColor: selectedTab === "Teacher" ? "#E96193" : "transparent",
      border: "2px solid #E96293",
      borderRadius: "5px",
      transition: "all 0.3s ease-in-out",
      boxShadow:
        selectedTab === "Teacher"
          ? "0 4px 10px rgba(40, 167, 69, 0.5)"
          : "none",
      cursor: "pointer",
    }}
    onMouseEnter={(e) =>
      (e.target.style.backgroundColor = selectedTab === "Teacher"
        ? "#E96193"
        : "#f0f0f0")
    }
    onMouseLeave={(e) =>
      (e.target.style.backgroundColor = selectedTab === "Teacher"
        ? "#E96193"
        : "transparent")
    }
  >
    Teacher
  </Button>
</div>
                  <Form className="form-container" onSubmit={handleFormSubmit}>
                    <Row>
                      {/* Common Fields */}
                      <Col sm={6}>
                        <Form.Control
                          type="text"
                          value={formDetails.firstName}
                          placeholder="First Name"
                          onChange={(e) => handleFormUpdate("firstName", e.target.value)}
                        />
                      </Col>
                      <Col sm={6}>
                        <Form.Control
                          type="text"
                          value={formDetails.lastName}
                          placeholder="Last Name"
                          onChange={(e) => handleFormUpdate("lastName", e.target.value)}
                        />
                      </Col>
                      <Col sm={6}>
                        <Form.Control
                          type="email"
                          value={formDetails.email}
                          placeholder="Email Address"
                          onChange={(e) => handleFormUpdate("email", e.target.value)}
                        />
                      </Col>
                      <Col sm={6}>
                        <Form.Control
                          type="tel"
                          value={formDetails.phone}
                          placeholder="Phone No."
                          onChange={(e) => handleFormUpdate("phone", e.target.value)}
                        />
                      </Col>
                      <Col sm={6}>
                        <Form.Control
                          type="text"
                          value={formDetails.address}
                          placeholder="Full address"
                          onChange={(e) => handleFormUpdate("address", e.target.value)}
                        />
                      </Col>
                      <Col sm={6}>
  <Form.Control
    as="select"
    value={formDetails.gender}
    onChange={(e) => handleFormUpdate("gender", e.target.value)}
    style={{ height: "60px" }} // Adjust the height to match other fields
  >
    <option value="">Select Gender</option>
    <option value="Male">Male</option>
    <option value="Female">Female</option>
  </Form.Control>
</Col>
                      {/* Conditional Fields */}
                      {formDetails.applyFor === "Student" && (
                        <>
                          <Col sm={6}>
                            <Form.Control
                              type="text"
                              value={formDetails.skillOrSubject}
                              placeholder="Subject/Skill want to Learn "
                              onChange={(e) => handleFormUpdate("skillOrSubject", e.target.value)}
                            />
                          </Col>
                          <Col sm={6}>
                            <Form.Control
                              as="select"
                              value={formDetails.mode}
                              onChange={(e) => handleFormUpdate("mode", e.target.value)}
                              style={{ height: "60px" }}
                            >
                              <option value="">Mode</option>
                              <option value="Online">Online</option>
                              <option value="In Person">In Person(ባሉበት ቦታ)</option>
                            </Form.Control>
                          </Col>
                        </>
                      )}
                      {formDetails.applyFor === "Teacher" && (
                        <>
                          <Col sm={6}>
                            <Form.Control
                              type="text"
                              value={formDetails.skillOrSubject}
                              placeholder="Subject to Teach"
                              onChange={(e) => handleFormUpdate("skillOrSubject", e.target.value)}
                            />
                          </Col>
                          <Col sm={6}>
                            <Form.Control
                              as="select"
                              value={formDetails.currentLevel}
                              onChange={(e) => handleFormUpdate("currentLevel", e.target.value)}
                              style={{ height: "60px" }}
                            >
                              <option value="">Select Current Level</option>
                              <option value="High School">High School</option>
                              <option value="Bachelor">Bachelor</option>
                              <option value="Master">Master</option>
                              <option value="PhD">PhD</option>
                            </Form.Control>
                          </Col>
                        </>
                      )}
                      <Col sm={12}>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          value={formDetails.message}
                          placeholder="Additional Information (ተጨማሪ ሚጨምሩት መረጃ ካለ አስቀምጡ)"
                          onChange={(e) => handleFormUpdate("message", e.target.value)}
                        />
                      </Col>
                    </Row>
                    {statusMessage && (
                      <div className={`status-message ${statusMessage.type}`}>
                        {statusMessage.text}
                      </div>
                    )}
                    <Button variant="primary" type="submit">
                      {isSubmitting ? "Submitting..." : "Apply Now"}
                    </Button>
                   
                  </Form>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};