import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/exitexam.jpg";
import projImg2 from "../assets/img/scholarships.jpg";
import projImg3 from "../assets/img/shows.jpeg";
import projImg4 from "../assets/img/notes.jpg";
import projImg5 from "../assets/img/entrance.png";
import projImg6 from "../assets/img/bank.jpg";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {
  const projects = [
    {
      title: "University Exit Exams",
      description: "Questions and Detailed Answers",
      imgUrl: projImg1,
    },
    {
      title: "Grade 12 National Exams",
      description: "Entrance Exams & Detailed Answers",
      imgUrl: projImg5,
    },
    {
      title: "Other Ethiopian Exams",
      description: "GAT, Bank, Airlines, and More",
      imgUrl: projImg6,
    },
    {
      title: "Short Notes & Materials",
      description: "Important notes and materials",
      imgUrl: projImg4,
    },
    {
      title: "Lives Shows & Events",
      description: "Important events and conferences",
      imgUrl: projImg3,
    },
    {
      title: "Scholarships & Trainings",
      description: "Free Sccholarships and Training",
      imgUrl: projImg2,
    },
  ];

  return (
    <section className="project" id="services">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  {/* First Row: Project Done */}
                  <Tab.Container
                    id="projects-tabs"
                    defaultActiveKey="first"
                  >
                  <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link style={{ fontSize: '1.4rem',
      fontWeight: 'bold'}} eventKey="first">App Features</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    </Tab.Container>
                  {/* Second Row: Descriptive Text */}
                 

                  <Tab.Container
                    id="projects-tabs"
                    defaultActiveKey="first"
                  >
                    
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible
                          ? "animate__animated animate__slideInUp"
                          : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => {
                            return (
                              <ProjectCard key={index} {...project} />
                            );
                          })}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
        <Row id="about-section">
  {/* About Section Title */}
  <h2
    style={{
      textAlign: "center",
      fontSize: "1.8rem", // Default for desktop
      color: "#343a40",
      marginBottom: "1rem",
      fontWeight: "bold",
      // Responsive font size
      "@media (max-width: 768px)": {
        fontSize: "1.5rem", // Tablet
      },
      "@media (max-width: 480px)": {
        fontSize: "1.2rem", // Mobile
      },
    }}
  >
    About
  </h2>

  {/* About Section Content with Bullets */}
  <ul
    style={{
      listStyle: "none",
      padding: "0",
      textAlign: "left",
      maxWidth: "800px",
      margin: "0 auto 2rem auto",
      lineHeight: "1.6",
      // Responsive alignment
      "@media (max-width: 768px)": {
        textAlign: "center", // Center bullets on tablet
      },
      "@media (max-width: 480px)": {
        textAlign: "left", // Align bullets left for mobile
      },
    }}
  >
    <li
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "1rem",
        fontSize: "1.1rem", // Default
        color: "#495057",
        // Adjust for smaller screens
        "@media (max-width: 768px)": {
          fontSize: "1rem", // Tablet
        },
        "@media (max-width: 480px)": {
          fontSize: "0.9rem", // Mobile
        },
      }}
    >
      <span
        style={{
          display: "inline-block",
          width: "30px",
          height: "30px",
          backgroundColor: "#007bff",
          color: "#fff",
          borderRadius: "50%",
          textAlign: "center",
          lineHeight: "30px",
          marginRight: "10px",
          fontSize: "1rem",
          // Adjust icon size for mobile
          "@media (max-width: 480px)": {
            width: "25px",
            height: "25px",
            fontSize: "0.8rem",
          },
        }}
      >
        📱
      </span>
      5000+ downloads in few months, on app stores and counting, helping students excel in exams.
    </li>
    <li
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "1rem",
        fontSize: "1.1rem",
        color: "#495057",
        "@media (max-width: 768px)": {
          fontSize: "1rem",
        },
        "@media (max-width: 480px)": {
          fontSize: "0.9rem",
        },
      }}
    >
      <span
        style={{
          display: "inline-block",
          width: "30px",
          height: "30px",
          backgroundColor: "#28a745",
          color: "#fff",
          borderRadius: "50%",
          textAlign: "center",
          lineHeight: "30px",
          marginRight: "10px",
          fontSize: "1rem",
          "@media (max-width: 480px)": {
            width: "25px",
            height: "25px",
            fontSize: "0.8rem",
          },
        }}
      >
        ⭐
      </span>
      Newly liked and rated highly by students across the country for its unique features.
    </li>
    <li
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "1rem",
        fontSize: "1.1rem",
        color: "#495057",
        "@media (max-width: 768px)": {
          fontSize: "1rem",
        },
        "@media (max-width: 480px)": {
          fontSize: "0.9rem",
        },
      }}
    >
      <span
        style={{
          display: "inline-block",
          width: "30px",
          height: "30px",
          backgroundColor: "#ffc107",
          color: "#fff",
          borderRadius: "50%",
          textAlign: "center",
          lineHeight: "30px",
          marginRight: "10px",
          fontSize: "1rem",
          "@media (max-width: 480px)": {
            width: "25px",
            height: "25px",
            fontSize: "0.8rem",
          },
        }}
      >
        📚
      </span>
      Packed with educational resources, including videos, PDFs, and practice questions.
    </li>
    <li
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "1rem",
        fontSize: "1.1rem",
        color: "#495057",
        "@media (max-width: 768px)": {
          fontSize: "1rem",
        },
        "@media (max-width: 480px)": {
          fontSize: "0.9rem",
        },
      }}
    >
      <span
        style={{
          display: "inline-block",
          width: "30px",
          height: "30px",
          backgroundColor: "#17a2b8",
          color: "#fff",
          borderRadius: "50%",
          textAlign: "center",
          lineHeight: "30px",
          marginRight: "10px",
          fontSize: "1rem",
          "@media (max-width: 480px)": {
            width: "25px",
            height: "25px",
            fontSize: "0.8rem",
          },
        }}
      >
        🎓
      </span>
      Offers access to scholarships, training, and Live events and Shows.
    </li>
  </ul>
</Row>

<Row id="features-section">
  {/* Main Description */}
  <p
    style={{
      textAlign: "center",
      fontSize: "1.1rem",
      color: "#6c757d",
      marginBottom: "2rem",
      padding: "20px",
      borderRadius: "25px",
      border: "4px solid",
      WebkitBackgroundClip: "text",
      boxShadow: "0 0 10px rgba(0,0,0,0.5)",
    }}
  >
    Ethio Exam Mastery is your ultimate companion for excelling in{" "}
    <strong>University Exit Exams</strong>,{" "}
    <strong>Grade 12 National Exams</strong>, <strong>GAT</strong>,{" "}
    <strong>Bank Exams</strong>, <strong>Airlines Exam</strong>, and more. It
    offers a rich collection of practice questions, detailed explanations, and{" "}
    <strong>educational resources</strong> like videos and PDFs. Stay ahead
    with <strong>live shows</strong>, <strong>events</strong>, conferences, and{" "}
    <strong>training</strong> sessions, along with progress tracking and access
    to <strong>scholarships</strong>—your path to success starts here!
  </p>
</Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="background" />
    </section>
  );
};