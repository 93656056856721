import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAY-dVCprKvFKe2taE7WEsQO_08xwA7iRI",
    authDomain: "myquizapp-16065.firebaseapp.com",
    projectId: "myquizapp-16065",
    storageBucket: "myquizapp-16065.appspot.com",
    messagingSenderId: "353524754758",
    appId: "1:353524754758:web:7f1e131f00a9373816dd47",
    measurementId: "G-JELXFGCSGJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };