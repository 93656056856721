import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import coder from "../assets/img/files.png";
import qrcode from "../assets/img/qrcode.png";
import playstoreIcon from "../assets/img/playstor.svg";
import appstoreIcon from "../assets/img/aple.svg";
import amazonIcon from "../assets/img/amazon.svg";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "Exit Exams", "12 National Exams", "Entrance Exams", "Other Ethio Exams", "Various Services" ];
  const period = 2000;
  const handleConnectClick = () => {
    // Scroll to the footer section
    const footer = document.getElementById('apply');
    if (footer) {
      footer.scrollIntoView({ behavior: 'smooth' });
    }
  };
  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])
  useEffect(() => {
    const dropletContainer = document.querySelector('.droplets');
    const numberOfDroplets = 7;

    // Create droplets dynamically
    for (let i = 0; i < numberOfDroplets; i++) {
      const droplet = document.createElement('div');
      droplet.classList.add('droplet');
      droplet.style.left = `${Math.random() * 100}vw`; // Random horizontal position
      droplet.style.animationDelay = `${Math.random() * 2}s`; // Random delay
      droplet.style.animationDuration = `${Math.random() * 6 + 4}s`; // Random speed
      dropletContainer.appendChild(droplet);
    }
  }, []);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-start">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <div className="resume-container">
             </div>
             <div className="droplets"></div>
             <h1 style={{ textAlign: "center", fontSize: "2.5rem", color: "#4995B9", fontWeight: "bold", marginBottom: "10px" }}>
    Ethio Exam Mastery
  </h1>
  <h2
    className="txt-rotate"
    style={{ fontSize: "1.8rem", color: "#555", fontWeight: "500", textAlign: "center",}}
    dataPeriod="1000"
    data-rotate='[ "Exit Exams", "Entrance Exams", "Other Ethio Exams", "Various Services" ]'
  >
    <span className="wrap">{text}</span>
  </h2> 
  <div
  style={{
    backgroundColor: "#F4F4F4", // Light gray background
    border: "1px solid rgba(0, 0, 0, 0.3)", // Subtle black border
    padding: "20px",
    borderRadius: "8px",
    maxWidth: "600px",
    margin: "20px auto", // Center horizontally
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
    position: "relative", // For positioning the bullet
  }}
>
  <span
    style={{
      position: "absolute",
      top: "24px",
      left: "10px",
      width: "15px",
      height: "15px",
      backgroundColor: "#E96192", // Diamond color (pink)
      transform: "rotate(45deg)", // Create diamond shape
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)", // Add subtle shadow
    }}
  ></span>
  <p
    style={{
      color: "#E96192", // Text color
      fontSize: "1rem",
      lineHeight: "1.6",
      textAlign: "justify", // Justify text alignment
      margin: "0",
      paddingLeft: "20px", // Indent text to make space for the diamond
    }}
  >
    Welcome to Ethio Exam Mastery: your all-in-one platform for success! From
    detailed answers to Ethiopian exams to free scholarship and training links,
    tutorial services, live events & shows, we bring everything you need to
    excel. Simplify your journey to achievement with Ethio Exam Mastery!
  </p>
</div>
                  <div style={{ textAlign: "center", marginTop: "20px" }}>
  <h2
    style={{
      fontSize: "1.8rem",
      color: "#4995B9",
      fontWeight: "bold",
      marginBottom: "10px",
      animation: "fadeIn 1s ease-in-out",
    }}
  >
    Download Below
  </h2>
  <p
    style={{
      fontSize: "1rem",
      color: "#E76495",
      marginBottom: "20px",
      animation: "fadeIn 1.5s ease-in-out",
    }}
  >
    Get our app on your favorite platform
  </p>
  <div
    className="download-buttons"
    style={{
      display: "flex",
      justifyContent: "center", // Centers the icons horizontally
      alignItems: "center",
      gap: "20px", // Adds space between icons
    }}
  >
    <a
      href="https://play.google.com/store/apps/details?id=com.ethio.exam_mastery&hl=en"
      target="_blank"
      rel="noreferrer"
      style={{ display: "block" }}
    >
      <img
        src={playstoreIcon}
        alt="Play Store"
        style={{
          width: "100px",
          height: "60px",
          transition: "transform 0.3s ease",
        }}
        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
      />
    </a>

    <a
      href="https://www.amazon.com/dp/B0D3H836FZ/ref=apps_sf_sta"
      target="_blank"
      rel="noreferrer"
      style={{ display: "block" }}
    >
      <img
        src={amazonIcon}
        alt="Amazon Store"
        style={{
          width: "100px",
          height: "60px",
          transition: "transform 0.3s ease",
        }}
        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
      />
    </a>

    <a
      href="https://www.apple.com/app-store/"
      target="_blank"
      rel="noreferrer"
      style={{ display: "block" }}
    >
      <img
        src={appstoreIcon}
        alt="App Store"
        style={{
          width: "100px",
          height: "60px",
          transition: "transform 0.3s ease",
        }}
        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
      />
    </a>
  </div>

  {/* QR Code Section */}
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      padding: "10px",
      marginTop: "10px",
      backgroundColor: "#f0f0f0",
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      <img
        src={qrcode} // Replace with actual QR code image URL
        alt="QR Code to App"
        style={{
          width: "400px", 
          height: "210px", 
          borderRadius: "5px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      />
    </div>
    <p
      style={{
        fontSize: "1rem",
        color: "#555",
        marginTop: "5px",
        maxWidth: "400px",
      }}
    >
      Scan the QR code above using your mobile device to download the app directly.
    </p>
  </div>

  {/* Add animation styles */}
  <style>
    {`
      @keyframes fadeIn {
        0% { opacity: 0; transform: translateY(-10px); }
        100% { opacity: 1; transform: translateY(0); }
      }

      /* Responsive Styling */
      @media (max-width: 768px) {
        h2 {
          font-size: 1.6rem;
        }
        p {
          font-size: 0.9rem;
        }
        .download-buttons {
          flex-direction: column;
          gap: 15px;
        }
        img {
          width: 80px; 
          height: 50px;
        }
        .qr-code {
          width: 160px;
          height: 160px;
        }
      }

      @media (max-width: 480px) {
        h2 {
          font-size: 1.4rem;
        }
        p {
          font-size: 0.8rem;
        }
        .download-buttons {
          gap: 10px;
        }
        img {
          width: 70px;
          height: 45px;
        }
        .qr-code {
          width: 140px;
          height: 140px;
        }
      }
    `}
  </style>
</div>

              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5} className="p-0">
  <TrackVisibility>
    {({ isVisible }) => (
      <div
        className={`d-flex flex-row justify-content-between align-items-center ${isVisible ? "animate__animated animate__zoomIn" : ""}`}
        style={{ gap: "0px" }} // Minimal gap between images
      >
        <img 
          src={coder} 
          alt="Header Img" 
          style={{ padding: "0px", width: "450px", height: "450px" }} // Adjust size
        />
       
    
      </div>
    )}
  </TrackVisibility>
</Col>

        </Row>
        <div
  style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // Optional: Ensures vertical centering within the viewport
  }}
>
  <button
    onClick={handleConnectClick}
    style={{
      backgroundColor: "#183852",
      color: 'white',
      border: 'none',
      padding: '15px 20px',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      cursor: 'pointer',
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      borderRadius: '5px',
      transition: 'background-color 0.3s ease',
    }}
    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'black')}
    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#183852')}
  >
    Learn & Teach <ArrowRightCircle size={25} style={{ marginLeft: '10px' }} />
  </button>
</div>
      </Container>
    </section>
  )
}
