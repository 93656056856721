import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import photo from "../assets/img/icon.png";
import { HashLink } from "react-router-hash-link";
import { BrowserRouter as Router } from "react-router-dom";

export const NavBar = ({ isDarkMode }) => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  // Gradient Colors
  const lightGradient = "linear-gradient(to right, #00aac7, #f86293)";
  const darkGradient = "linear-gradient(to right, #2E3c62, #99ace1)";

  const backgroundStyle = {
    background: isDarkMode ? darkGradient : lightGradient,
    transition: "background 0.3s ease",
    padding: "8px 0",
  };

  const navLinkStyle = {
    color: "#fff",
    padding: "5px 15px",
    fontSize: "1rem",
    textAlign: "center",
    margin: "0 5px",
    transition: "color 0.3s ease",
  };

  const activeLinkStyle = {
    color: "#E76495",
    borderBottom: "2px solid #E76495",
  };

  return (
    <Router>
      <Navbar
        expand="md"
        className={scrolled ? "scrolled" : ""}
        style={backgroundStyle}
      >
        <Container>
          {/* Logo and Brand Name */}
          <Navbar.Brand
            href="/"
            style={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            <img
              src={photo}
              alt="Logo"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
            <span
              style={{
                fontSize: "1.3rem",
                fontWeight: "bold",
                color: "#fff",
                textTransform: "uppercase",
                cursor: "pointer",
                transition: "color 0.3s ease",
              }}
              onMouseEnter={(e) => (e.target.style.color = "#E76495")}
              onMouseLeave={(e) => (e.target.style.color = "#fff")}
            >
              Ethio Exam Mastery
            </span>
          </Navbar.Brand>

          {/* Navbar Toggle */}
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>

          {/* Navbar Links */}
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto" style={{ display: "flex", flexWrap: "wrap" }}>
              <Nav.Link
                href="#home"
                className={activeLink === "home" ? "active navbar-link" : "navbar-link"}
                onClick={() => onUpdateActiveLink("home")}
                style={activeLink === "home" ? { ...navLinkStyle, ...activeLinkStyle } : navLinkStyle}
              >
                Home
              </Nav.Link>
              <Nav.Link
                href="#services"
                className={activeLink === "services" ? "active navbar-link" : "navbar-link"}
                onClick={() => onUpdateActiveLink("services")}
                style={activeLink === "services" ? { ...navLinkStyle, ...activeLinkStyle } : navLinkStyle}
              >
                Services
              </Nav.Link>
              <Nav.Link
                href="#about-section"
                className={activeLink === "about-section" ? "active navbar-link" : "navbar-link"}
                onClick={() => onUpdateActiveLink("about-section")}
                style={activeLink === "about-section" ? { ...navLinkStyle, ...activeLinkStyle } : navLinkStyle}
              >
                About
              </Nav.Link>
              <Nav.Link
                href="#apply"
                className={activeLink === "apply" ? "active navbar-link" : "navbar-link"}
                onClick={() => onUpdateActiveLink("apply")}
                style={activeLink === "apply" ? { ...navLinkStyle, ...activeLinkStyle } : navLinkStyle}
              >
                Apply
              </Nav.Link>
              <Nav.Link
                href="#follow"
                className={activeLink === "follow" ? "active navbar-link" : "navbar-link"}
                onClick={() => onUpdateActiveLink("follow")}
                style={activeLink === "follow" ? { ...navLinkStyle, ...activeLinkStyle } : navLinkStyle}
              >
                Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  );
};