
import './App.css';
import { Banner } from './component/Banner';
import { NavBar } from './component/NavBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Projects } from './component/Projects';
import { Contact } from './component/Contact';
import { Footer } from './component/Footer';


function App() {
  return (
    <div className="App">
     <NavBar />
      <Banner />
      <Projects />
      {/* <Skills /> */}
    
      <Contact />
      <Footer />

    </div>
  );
}

export default App;
